<template>
  <div>
    <Progress v-if="loading" />
    <v-card tile v-if="!loading">
      <v-card-text class="text-center">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="10">
               <v-row>
                <v-col cols="3">
                  <v-autocomplete
                          v-model="product_id"
                          :items="products"
                          label="Product"
                          item-text="name"
                          item-value="id"
                           @change="getData()"

                          >
                            <!-- <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('product')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('product',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template> -->
                            <template v-slot:item="data">
                              <dove v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </dove>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('product',data.item.image_link)"/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                 </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="from"
                          label="From"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        @input="from_menu = false"
                        @change="getData()"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to"
                          label="To"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to"
                        @input="to_menu = false"
                        @change="getData()"
                        :min="from"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                          v-model="branch_id"
                          :items="branchs"
                          label="Branch"
                          item-text="name"
                          item-value="id"

                    @input="getData()"
                  >
                  <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                  </v-autocomplete>
                  </v-col>
                </v-row>
            </v-col>

            <!-- <v-col cols="2" align="right">
              <v-btn color="primary" medium tile elevation="1" @click="add()">

                Filter
              </v-btn>
            </v-col> -->
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="data_stock"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
        >
          <!-- <template v-slot:item.partner="{ item }">
            <span @click="openDetail(item)">
              {{ item.partner ? item.partner.name : "" }}
            </span>
          </template> -->
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
          ></v-pagination>
          <!-- <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field> -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<style>
  .v-menu__content.theme--light.menuable__content__active > .v-list > .v-list-item__content > .v-list-item__title{
    color: black !important;
  }
</style>
<script>
// @ is an alias to /src
import Progress from "../../../components/external/Progress";
import { mapActions, mapGetters } from "vuex";
import GlobalMixin from "../../mixin/index";
export default {
  mixins: [GlobalMixin],
  data() {
    return {
      user_edit: null,
      dialog_delete: false,
      dialog_detail: false,
      product_id:'',
      data_detail: {},
      data_stock:[],
      products:[],
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      branch_id:'',
      from_menu:false,
      to_menu:false,
      headers: [

        {
          text: "Product",
          value: "product"
        },
        {
          text: "Doc Type",
          value: "doc_type"
        },
        {
          text: "Doc Date",
          value: "doc_date"
        },
        {
          text: "Doc No",
          value: "doc_no"
        },

        {
          text: "Note",
          value: "note"
        },
        {
          text: "Qty",
          value: "qty"
        },
         {
          text: "Stock",
          value: "stock"
        },
      ]
    };
  },
  computed: {
    ...mapGetters({
      pos: "data/pos",

      branchs: 'data/branchs',

    })
  },
  components: {
    Progress,

  },
  methods: {
    ...mapActions({
      setEditData: "cart/setEditData",
      setCart: "cart/setCart"
    }),

    openDetail: function(item = {}) {
      this.data_detail = item;
      this.dialog_detail = !this.dialog_detail;
    },
    edit(item) {
      let data = {
        id: item.id,
        doc_no: item.doc_no,
        doc_date: item.doc_date,
        doc_type: item.doc_type,
        partner_id: item.partner_id,
        payment_id: item.payment_id,
        branch_id: item.branch_id,
        company_id: item.company_id,
        sales_type_id: item.sales_type_id,
        name: item.name,
        note: item.note,
        status_edit: true
      };
      let cart = item.transaction_details.map(x => {
        let product = {
          id: x.product_id,
          qty: parseInt(x.qty),
          pesan: parseInt(x.qty),
          price: parseInt(x.price),
          name: x.product.name,
          checkhed: true
        };
        return product;
      });
      this.setCart(cart);
      this.setEditData(data);
      this.add();
    },
    close() {
      this.setEditData(null);
      this.user_edit = null;
      this.dialog_delete = false;
      this.dialog_form = false;
    },
    img: function (folder,file) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
    async go(table, store) {
      let params = `branch_id=${this.branch_id}&from=${this.from}&to=${this.to}`
      await this.axios
        .get("/v1/" + table + "?company_id=" + this.user.company_id + '&' + params, {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        })
        .then(async ress => {
          await this.setData({ data: ress.data.data, table_name: store });
        })
        .catch(err => console.log(err));
      this.loading = false;
    },
    async getData() {
      let params = `branch_id=${this.branch_id}&start_date=${this.from}&end_date=${this.to}&product_id=${this.product_id}`
      await this.axios
        .get("/v1/stock/stock-balance" + "?company_id=" + this.user.company_id + '&' + params, {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        })
        .then(async ress => {
          console.log(ress)
          let stock = ress.data.stock.length > 0 ? parseInt(ress.data.stock[0].stock) : 0
          let balance_stock = ress.data.stock.length > 0 ? parseInt(ress.data.stock[0].stock) : 0
          let total = ress.data.data.map((x) => {
            stock = stock + x.qty
            let data = {...x,stock:stock}
            return data
          })
          this.data_stock = [
            {
              product:'Stock Balance',
              stock: balance_stock,
              doc_type:	"",
              doc_date:	"",
              doc_no:	"",
              note:	""
            },
            ...total
          ]
          

          console.log(this.data_stock)
        })
        .catch(err => console.log(err));
      this.loading = false;
    },
    async get_data_master_product (table,param) {
      await this.axios.get(`/v1/${table}/all?company_id=` + this.user.company_id + '&' + param, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          this.products = ress.data.data

        })
        .catch(err => console.log(err))
        this.progress = false
    },
  },
  filters: {
    tgl_sales: function(value) {
      let time = new Date(value);
      let tgl =
        parseInt(time.getDate()) < 10 ? "0" + time.getDate() : time.getDate();
      let bln =
        time.getMonth() + 1 < 10
          ? "0" + (time.getMonth() + 1)
          : time.getMonth() + 1;
      let jam = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
      let menit =
        time.getUTCMinutes() <= 9
          ? "0" + time.getUTCMinutes().toString()
          : time.getUTCMinutes();
      let date = `${bln}-${tgl}-${time.getFullYear()} ` + jam + ":" + menit;
      return date;
    }
  },
  async created() {
    if (this.pos.length) {
      this.loading = false;
    }
    await this.get_data_master_product('product','is_stock=Y')
    await this.get_data_master('branch','branchs')
    this.getData()

  },
  async mounted() {
    let date = new Date()
    let bln =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
    let year = date.getFullYear()
    this.from = new Date(`${year}-${bln}-01`).toISOString().substr(0, 10)
    await this.go("transactions/pos", "pos");
  }
};
</script>
